<template>
  <div id="page-sets">
    <v-container grid-list-xl fluid>
      <v-row>
        <v-col cols="12" md="6">
          <v-card>
            <v-card-title>{{
              $vuetify.lang.t('$vuetify.menu.sets')
            }}</v-card-title>
            <v-toolbar flat color="white">
              <v-autocomplete
                v-model="selectedSearchSetsItem"
                :items="foundedSetsItems"
                :loading="loadingSearchSets"
                :search-input.sync="searchSetsInput"
                clearable
                hide-details
                hide-selected
                item-text="ordernumber"
                return-object
                :label="$vuetify.lang.t('$vuetify.toolbar.search')"
                solo
                flat
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $vuetify.lang.t('$vuetify.sets.searchSets') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="item.ordernumber"
                    ></v-list-item-title>
                    <v-list-item-subtitle
                      v-text="item.name"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-on="on" v-bind="attrs" @click="init">
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </template>
                <span>{{ $vuetify.lang.t('$vuetify.buttons.refresh') }}</span>
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text class="pa-0">
              <v-data-table
                v-model="selectedSets"
                :headers="compSetsHeaders"
                :items="sets"
                fixed-header
                single-select
                show-select
                class="elevation-1"
                item-key="ordernumber"
                :items-per-page="-1"
              >
                <template v-slot:item.saved="{ item }">
                  <v-simple-checkbox
                    v-model="item.saved"
                    disabled
                  ></v-simple-checkbox>
                </template>
                <template v-slot:item.action="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-on="on"
                        v-bind="attrs"
                        @click="confirmDeleteSet(item)"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>{{
                      $vuetify.lang.t('$vuetify.buttons.delete')
                    }}</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card>
            <v-card-title
              >{{ $vuetify.lang.t('$vuetify.different.items') }}
            </v-card-title>
            <v-toolbar flat color="white">
              <v-autocomplete
                v-model="selectedSearchItem"
                :items="foundedItems"
                :loading="loadingSearchItems"
                :search-input.sync="searchItemsInput"
                clearable
                hide-details
                no-filter
                item-text="ordernumber"
                return-object
                :label="$vuetify.lang.t('$vuetify.toolbar.search')"
                solo
                flat
                :disabled="!selectedSets.length"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $vuetify.lang.t('$vuetify.sets.searchItems') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="item.ordernumber"
                    ></v-list-item-title>
                    <v-list-item-subtitle
                      v-text="item.name"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-on="on"
                    v-bind="attrs"
                    :disabled="!selectedSets.length"
                    @click="getItems(selectedSets[0].id)"
                  >
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </template>
                <span>{{ $vuetify.lang.t('$vuetify.buttons.refresh') }}</span>
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text class="pa-0">
              <v-data-table
                :headers="compItemsHeaders"
                :items="items"
                fixed-header
                class="elevation-1"
                item-key="ordernumber"
                :items-per-page="-1"
              >
                <template v-slot:item.quantity="props">
                  <v-edit-dialog
                    :return-value.sync="props.item.quantity"
                    large
                    persistent
                    :cancel-text="$vuetify.lang.t('$vuetify.buttons.cancel')"
                    :save-text="$vuetify.lang.t('$vuetify.buttons.save')"
                    @save="updateItem(props.item)"
                  >
                    {{ props.item.quantity }}
                    <template v-slot:input>
                      <v-text-field
                        v-model="props.item.quantity"
                        :rules="[rules.required, rules.min1Num]"
                        :label="$vuetify.lang.t('$vuetify.buttons.edit')"
                        single-line
                        counter
                        type="number"
                        min="1"
                        step="1"
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:item.action="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-on="on"
                        v-bind="attrs"
                        @click="confirmDeleteItem(item)"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>{{
                      $vuetify.lang.t('$vuetify.buttons.delete')
                    }}</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar
      :timeout="3000"
      bottom
      right
      :color="snackbar.color"
      v-model="snackbar.show"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text @click.native="snackbar.show = false" icon>
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="dialogDelete" persistent max-width="500px">
      <v-card>
        <v-card-title class="headline"
          >{{ $vuetify.lang.t('$vuetify.different.deleteItemConfirm') }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogDelete = false"
            :disabled="loading"
          >
            {{ $vuetify.lang.t('$vuetify.buttons.cancel') }}
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="deleteItem"
            :loading="loading"
            >{{ $vuetify.lang.t('$vuetify.buttons.delete') }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogSetDelete" persistent max-width="500px">
      <v-card>
        <v-card-title class="headline"
          >{{ $vuetify.lang.t('$vuetify.different.deleteItemConfirm') }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogSetDelete = false"
            :disabled="loading"
          >
            {{ $vuetify.lang.t('$vuetify.buttons.cancel') }}
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="deleteSet"
            :loading="loading"
            >{{ $vuetify.lang.t('$vuetify.buttons.delete') }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <AppLoader :dialog.sync="loading"></AppLoader>
  </div>
</template>

<script>
import AppLoader from '@/components/AppLoader'

export default {
  components: {
    AppLoader
  },
  name: 'Sets',
  data() {
    return {
      projectId: 2,
      loading: false,
      dialogDelete: false,
      dialogSetDelete: false,
      awaitingSetsSearch: false,
      awaitingItemsSearch: false,
      loadingSearchSets: false,
      loadingSearchItems: false,
      searchSetsInput: null,
      searchItemsInput: null,
      sets: [],
      items: [],
      foundedSetsItems: [],
      foundedItems: [],
      selectedSearchSetsItem: null,
      selectedSearchItem: null,
      selectedSets: [],
      selectedSet: null,
      selectedItem: null,
      snackbar: {
        show: false,
        text: '',
        color: ''
      },
      rules: {
        required: (v) =>
          !!v || this.$vuetify.lang.t('$vuetify.validation.required'),
        min1Num: (v) =>
          (v && v * 1 >= 1) || this.$vuetify.lang.t('$vuetify.validation.min1')
      }
    }
  },
  watch: {
    searchSetsInput(val) {
      if (!this.awaitingSetsSearch && val) {
        setTimeout(() => {
          this.searchSets()
          this.awaitingSetsSearch = false
        }, 1000)
        this.awaitingSetsSearch = true
      }
    },
    searchItemsInput(val) {
      if (!this.awaitingItemsSearch && val) {
        setTimeout(() => {
          this.searchItems()
          this.awaitingItemsSearch = false
        }, 1000)
        this.awaitingItemsSearch = true
      }
    },
    selectedSearchSetsItem(val) {
      if (val) {
        let item = {
          id: val.id,
          name: val.name,
          ordernumber: val.ordernumber,
          saved: Boolean(Number(val.saved))
        }
        let index = this.sets.indexOf(item)
        if (index === -1) {
          this.sets.push(item)
          this.selectedSearchSetsItem = null
          this.searchSetsInput = null
        }
      }
    },
    selectedSets(val) {
      if (val && val.length) {
        this.getItems(val[0].id)
      } else this.items = []
    },
    selectedSearchItem(val) {
      if (val) {
        this.addItem(val)
      }
    }
  },
  metaInfo() {
    return {
      title: this.$vuetify.lang.t('$vuetify.menu.sets')
    }
  },
  mounted() {
    this.init()
  },
  computed: {
    compAuthHeader() {
      return {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token'),
          responseType: 'application/json'
        }
      }
    },
    compSetsHeaders() {
      return [
        { text: 'SKU', value: 'ordernumber' },
        {
          text: this.$vuetify.lang.t('$vuetify.different.title'),
          value: 'name'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.status.saved'),
          value: 'saved',
          sortable: false
        },
        {
          text: this.$vuetify.lang.t('$vuetify.buttons.action'),
          value: 'action',
          sortable: false
        }
      ]
    },
    compItemsHeaders() {
      return [
        { text: 'SKU', value: 'ordernumber' },
        {
          text: this.$vuetify.lang.t('$vuetify.different.title'),
          value: 'name'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.order.quantity'),
          value: 'quantity',
          sortable: false
        },
        {
          text: this.$vuetify.lang.t('$vuetify.buttons.action'),
          value: 'action',
          sortable: false
        }
      ]
    }
  },
  methods: {
    init() {
      let data = {
        project_id: this.projectId
      }
      this.loading = true
      this.$axios
        .post(
          this.$store.getters.getApiUrl + 'api/sets/index',
          data,
          this.compAuthHeader
        )
        .then((response) => {
          this.loading = false
          if (response.data.success) {
            let items = response.data.items
            items.map(function(item) {
              item.saved = Boolean(Number(item.saved))
            })
            this.sets = items
          } else {
            this.showSnackbar(
              'red',
              this.$vuetify.lang.t('$vuetify.errors.error')
            )
          }
        })
        .catch(() => {
          this.loading = false
          this.showSnackbar(
            'red',
            this.$vuetify.lang.t('$vuetify.errors.error')
          )
        })
    },
    searchSets() {
      let data = {
        project_id: this.projectId,
        search: this.searchSetsInput
      }
      if (this.searchSetsInput) {
        this.loadingSearchSets = true
        this.$axios
          .post(
            this.$store.getters.getApiUrl + 'api/sets/search',
            data,
            this.compAuthHeader
          )
          .then((response) => {
            this.loadingSearchSets = false
            if (response.data.success) {
              this.foundedSetsItems = response.data.items
            } else {
              this.showSnackbar(
                'red',
                this.$vuetify.lang.t('$vuetify.errors.error')
              )
            }
          })
          .catch(() => {
            this.loadingSearchSets = false
            this.showSnackbar(
              'red',
              this.$vuetify.lang.t('$vuetify.errors.error')
            )
          })
      } else this.foundedSetsItems = []
    },
    searchItems() {
      let data = {
        project_id: this.projectId,
        search: this.searchItemsInput,
        set_article_id: this.selectedSets[0].id
      }
      if (this.searchItemsInput) {
        this.loadingSearchItems = true
        this.$axios
          .post(
            this.$store.getters.getApiUrl + 'api/sets/items/search',
            data,
            this.compAuthHeader
          )
          .then((response) => {
            if (response.data.success) {
              this.foundedItems = response.data.items
            } else {
              this.showSnackbar(
                'red',
                this.$vuetify.lang.t('$vuetify.errors.error')
              )
            }
            this.loadingSearchItems = false
          })
          .catch(() => {
            this.loadingSearchItems = false
            this.showSnackbar(
              'red',
              this.$vuetify.lang.t('$vuetify.errors.error')
            )
          })
      } else this.foundedItems = []
    },
    getItems(setArticleId) {
      let data = {
        project_id: this.projectId,
        set_article_id: setArticleId
      }
      this.loading = true
      this.$axios
        .post(
          this.$store.getters.getApiUrl + 'api/sets/items',
          data,
          this.compAuthHeader
        )
        .then((response) => {
          this.loading = false
          if (response.data.success) {
            this.items = response.data.items
          } else {
            this.showSnackbar(
              'red',
              this.$vuetify.lang.t('$vuetify.errors.error')
            )
          }
        })
        .catch(() => {
          this.loading = false
          this.showSnackbar(
            'red',
            this.$vuetify.lang.t('$vuetify.errors.error')
          )
        })
    },
    updateItem(item) {
      let data = {
        project_id: this.projectId,
        id: item.id,
        quantity: item.quantity
      }
      this.loading = true
      this.$axios
        .post(
          this.$store.getters.getApiUrl + 'api/sets/item/update',
          data,
          this.compAuthHeader
        )
        .then((response) => {
          this.loading = false
          if (response.data.success) {
            this.showSnackbar(
              'green',
              this.$vuetify.lang.t('$vuetify.messages.saved')
            )
          } else {
            this.showSnackbar(
              'red',
              this.$vuetify.lang.t('$vuetify.errors.error')
            )
            this.getItems(this.selectedSets[0].id)
          }
        })
        .catch(() => {
          this.loading = false
          this.showSnackbar(
            'red',
            this.$vuetify.lang.t('$vuetify.errors.error')
          )
        })
    },
    addItem(item) {
      let data = {
        project_id: this.projectId,
        set_article_id: this.selectedSets[0].id,
        article_detail_id: item.id
      }
      this.loading = true
      this.$axios
        .post(
          this.$store.getters.getApiUrl + 'api/sets/item/add',
          data,
          this.compAuthHeader
        )
        .then((response) => {
          this.loading = false
          if (response.data.success) {
            this.showSnackbar(
              'green',
              this.$vuetify.lang.t('$vuetify.messages.saved')
            )
            this.getItems(this.selectedSets[0].id)

            if (!this.selectedSets[0].saved) {
              this.selectedSets[0].saved = true
            }
          } else {
            this.showSnackbar(
              'red',
              this.$vuetify.lang.t('$vuetify.errors.error')
            )
          }
        })
        .catch(() => {
          this.loading = false
          this.showSnackbar(
            'red',
            this.$vuetify.lang.t('$vuetify.errors.error')
          )
        })
    },
    deleteItem() {
      let data = {
        project_id: this.projectId,
        id: this.selectedItem.id
      }
      this.loading = true
      this.dialogDelete = false
      this.selectedItem = null

      this.$axios
        .post(
          this.$store.getters.getApiUrl + 'api/sets/item/delete',
          data,
          this.compAuthHeader
        )
        .then((response) => {
          this.loading = false
          if (response.data.success) {
            this.showSnackbar(
              'green',
              this.$vuetify.lang.t('$vuetify.messages.deleted')
            )
            this.getItems(this.selectedSets[0].id)
          } else {
            this.showSnackbar(
              'red',
              this.$vuetify.lang.t('$vuetify.errors.error')
            )
          }
        })
        .catch(() => {
          this.loading = false
          this.showSnackbar(
            'red',
            this.$vuetify.lang.t('$vuetify.errors.error')
          )
        })
    },
    confirmDeleteItem(item) {
      this.selectedItem = item
      this.dialogDelete = true
    },
    deleteSet() {
      let data = {
        project_id: this.projectId,
        set_article_id: this.selectedSet.id
      }
      this.loading = true
      this.dialogSetDelete = false
      this.selectedSet = null

      this.$axios
        .post(
          this.$store.getters.getApiUrl + 'api/sets/delete',
          data,
          this.compAuthHeader
        )
        .then((response) => {
          if (response.data.success) {
            this.showSnackbar(
              'green',
              this.$vuetify.lang.t('$vuetify.messages.deleted')
            )
            this.init()
          } else {
            this.loading = false
            this.showSnackbar(
              'red',
              this.$vuetify.lang.t('$vuetify.errors.error')
            )
          }
        })
        .catch(() => {
          this.loading = false
          this.showSnackbar(
            'red',
            this.$vuetify.lang.t('$vuetify.errors.error')
          )
        })
    },
    confirmDeleteSet(item) {
      this.selectedSet = item
      this.dialogSetDelete = true
    },
    showSnackbar(color, text) {
      this.snackbar.show = true
      this.snackbar.color = color
      this.snackbar.text = text
    }
  }
}
</script>
